<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";


export default defineComponent({
  name: "customer-details",
  components: {
    
  },
 
  setup() {

    const { t } = useI18n()
  
    onMounted(async () => {
      
      const currentBord = parseInt(localStorage.getItem("currentBord") || "0");

      const myListLiv = await getAxios("/getSVoneBord/"+ currentBord);
      const res = myListLiv.results

      let mType = res.bor_c_sort_versement == 'Elimination' ?  "d'élimination" : "de versement";
      
      let title = "Bordereau "+mType+" n° "+ res.bor_c_identifiant +"<div class='text-gray-600' style='font-size: 11px; font-weight: 600'>" + res.act_c_raison_sociale +'</div>';
      
      if (res.act_ark_c_nom2 !== null) title += "<div class='text-gray-600' style='font-size: 10px;'>" + res.act_ark_c_nom2 +'</div>';
      if (res.act_ark_c_nom3 !== null) title += "<div class='text-gray-600' style='font-size: 10px;'>" + res.act_ark_c_nom3 +'</div>';
      if (res.act_ark_c_nom4 !== null) title += "<div class='text-gray-600' style='font-size: 10px;'>" + res.act_ark_c_nom4 +'</div>';

      setCurrentPageBreadcrumbs(title, []);



    });
  
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
